import i18n from "../i18n";

export enum paths {
  HOME = "/",
  ALERTS_DETAIL = "/alerts/:id",
  SIGN_IN = "/sign-in",
  SIGN_UP = "/sign-up",
  API_KEYS = "/api-keys",
  RISK_SETTINGS = "/risks",
  RISK_SETTINGS_DETAILS = "/risks/:id",

  TRANSFERS = "/transfers",
  TRANSFERS_MONITORED_WALLETS = "/transfers/monitored-wallets",
  TRANSFERS_MONITORED_WALLETS_INFORMATION = "/transfers/monitored-wallets/:id",
  TRANSFERS_MONITORED_WALLETS_TRANSFER = "/transfers/monitored/monitored-wallets/:id/transfer/:transferId",
  TRANSFERS_MONITORED_WALLETS_INFORMATION_TRANSFERS = "/transfers/monitored-wallets/:id/transfers",

  TRANSFERS_DETAILS = "/transfers/:id",
  TRANSFERS_DETAILS_ALERT = "/transfers/:id/:alertId",
  TRANSFERS_DETAILS_ALERTS = "/transfers/:id/alerts",
  TRANSFERS_DETAILS_ALERTS_DETAILS = "/transfers/:id/alerts/:alertId",

  PLAN = "/plan",
  ACCESS_MANAGEMENT = "/access-management",
  ACCESS_MANAGEMENT_NEW = "/access-management-new",
  ACCESS_MANAGEMENT_DETAIL = "/access-management/:id",

  CLIENTS = "/clients",
  CLIENTS_DETAILS = "/clients/:id/detail",
  CLIENTS_DETAILS_ALERT = "/clients/:id/alert/:alertId",
  CLIENTS_DETAILS_ALERTS = "/clients/:id/alerts",
  CLIENTS_DETAILS_ALERTS_DETAILS = "/clients/:id/alerts/:alertId",

  CLIENTS_DETAILS_TRANSFER = "/clients/:id/transfer/:transferId",
  CLIENTS_DETAILS_TRANSFERS = "/clients/:id/transfers",
  CLIENTS_DETAILS_TRANSFERS_DETAILS = "/clients/:id/transfers/:transferId",
  CLIENTS_DETAILS_TRANSFERS_DETAILS_ALERTS = "/clients/:id/transfers/:transferId/alerts",
  CLIENTS_DETAILS_TRANSFERS_DETAILS_ALERT = "/clients/:id/transfers/:transferId/:alertId",

  CLIENTS_ARCHIVE = "/clients/archive",
  CLIENTS_ARCHIVE_DETAILS = "/clients/archive/:id/detail",
  CLIENTS_ARCHIVE_DETAILS_ALERT = "/clients/archive/:id/alert/:alertId",
  CLIENTS_ARCHIVE_DETAILS_ALERTS = "/clients//archive/:id/alerts",
  CLIENTS_ARCHIVE_DETAILS_ALERTS_DETAILS = "/clients//archive/:id/alerts/:alertId",
  CLIENTS_ARCHIVE_DETAILS_TRANSFER = "/clients/archive/:id/transfer/:transferId",
  CLIENTS_ARCHIVE_DETAILS_TRANSFERS = "/clients/archive/:id/transfers",
  CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS = "/clients/archive/:id/transfers/:transferId",
  CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS_ALERTS = "/clients/archive/:id/transfers/:transferId/alerts",
  CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS_ALERT = "/clients/archive/:id/transfers/:transferId/:alertsId",

  SETTINGS_SAFETY = "/settings/safety",

  INVOICES = "/invoices",
  INVOICES_MY_WALLETS = "/invoices/my-wallets",

  FAST_CHECKS = "/fast-checks",
  FAST_CHECKS_DETAILS = "/fast-checks/:id",
  FAST_CHECKS_DETAILS_RISKS = "/fast-checks/:id/risks/:alertId",
}

export const PATHS_TITLES: {[key in paths]?: string} = {
  [paths.HOME]: i18n.t("common.alerts"),
  [paths.ALERTS_DETAIL]: i18n.t("alertsDetail.alertDetails"),
  [paths.SIGN_IN]: i18n.t("auth.signIn"),
  [paths.SIGN_UP]: i18n.t("auth.signUp"),
  [paths.API_KEYS]: i18n.t("naming.apiKeys"),
  [paths.RISK_SETTINGS]: i18n.t("amlRiskSettings.title"),
  [paths.RISK_SETTINGS_DETAILS]: i18n.t("amlRiskSettings.title"),
  [paths.TRANSFERS]: i18n.t("common.transfers"),
  [paths.TRANSFERS_DETAILS]: i18n.t("common.transfers"),
  [paths.TRANSFERS_DETAILS_ALERT]: i18n.t("common.transfers"),
  [paths.TRANSFERS_DETAILS_ALERTS]: i18n.t("common.transfers"),
  [paths.TRANSFERS_DETAILS_ALERTS_DETAILS]: i18n.t("common.transfers"),
  [paths.TRANSFERS_MONITORED_WALLETS]: i18n.t("monitoredWallets.title"),
  [paths.PLAN]: i18n.t("plan.currentPlan"),
  [paths.ACCESS_MANAGEMENT]: i18n.t("accessManagement.accessManagement"),
  [paths.ACCESS_MANAGEMENT_NEW]: i18n.t("accessManagement.newTeamMember"),
  [paths.ACCESS_MANAGEMENT_DETAIL]: i18n.t("accessManagement.accessManagement"),
  [paths.CLIENTS]: i18n.t("clients.clients"),
  [paths.CLIENTS_DETAILS]: i18n.t("clients.clients"),
  [paths.CLIENTS_DETAILS_ALERT]: i18n.t("common.alerts"),
  [paths.CLIENTS_DETAILS_ALERTS]: i18n.t("common.alerts"),
  [paths.CLIENTS_DETAILS_ALERTS_DETAILS]: i18n.t("common.alerts"),
  [paths.CLIENTS_DETAILS_TRANSFERS]: i18n.t("clients.clients"),
  [paths.CLIENTS_DETAILS_TRANSFERS_DETAILS]: i18n.t("clients.clients"),
  [paths.CLIENTS_DETAILS_TRANSFERS_DETAILS_ALERT]: i18n.t("common.alerts"),
  [paths.CLIENTS_DETAILS_TRANSFERS_DETAILS_ALERTS]: i18n.t("common.alerts"),
  [paths.CLIENTS_DETAILS_TRANSFER]: i18n.t("clients.clients"),
  [paths.CLIENTS_ARCHIVE]: i18n.t("clients.archive"),
  [paths.CLIENTS_ARCHIVE_DETAILS]: i18n.t("clients.archive"),
  [paths.CLIENTS_ARCHIVE_DETAILS_ALERT]: i18n.t("common.alerts"),
  [paths.CLIENTS_ARCHIVE_DETAILS_ALERTS]: i18n.t("common.alerts"),
  [paths.CLIENTS_ARCHIVE_DETAILS_ALERTS_DETAILS]: i18n.t("common.alerts"),
  [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS]: i18n.t("clients.archive"),
  [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS]: i18n.t("clients.archive"),
  [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS_ALERT]: i18n.t("common.alerts"),
  [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS_ALERTS]: i18n.t("common.alerts"),
  [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFER]: i18n.t("clients.archive"),
  [paths.SETTINGS_SAFETY]: i18n.t("naming.securityAndEntry"),
  [paths.INVOICES]: i18n.t("invoices.title"),
  [paths.INVOICES_MY_WALLETS]: i18n.t("myWallets.title"),
  [paths.FAST_CHECKS]: i18n.t("fastChecks.title"),
  [paths.FAST_CHECKS_DETAILS]: i18n.t("fastChecks.title"),
  [paths.FAST_CHECKS_DETAILS_RISKS]: i18n.t("common.risks"),
};
export const pathTitle = (pathname: paths|undefined): string => {
  if (!pathname) return "";

  const title = PATHS_TITLES[pathname];
  return title ? i18n.t(PATHS_TITLES[pathname] || "") : "";
};

export const monitoringParamsKey = 'monitored_wallet';
export const clientParamsKey = 'client';
export const getClientParamKey = (id:string): string => `?${clientParamsKey}=${id}`;
export const getMonitoringParamKey = (id:string): string => `?${monitoringParamsKey}=${id}`;
export const getRiskSettingsDetailsPath = (id: string): string =>
  paths.RISK_SETTINGS_DETAILS.replace(":id", id);
export const getAlertsDetailPath = (id: string): string =>
  paths.ALERTS_DETAIL.replace(":id", id);
export const getTransfersDetailPath = (id: string): string =>
  paths.TRANSFERS_DETAILS.replace(":id", id);
export const getTransfersDetailAlertPath = (id: string, alertId: string): string =>
  paths.TRANSFERS_DETAILS_ALERT.replace(":id", id).replace(":alertId", alertId);
export const getTransfersDetailAlertsPath = (id: string): string =>
  paths.TRANSFERS_DETAILS_ALERTS.replace(":id", id);
export const getTransfersDetailAlertsDetailsPath = (id: string, alertId: string): string =>
  paths.TRANSFERS_DETAILS_ALERTS_DETAILS.replace(":id", id).replace(":alertId", alertId);
export const getAccessManagementDetailPath = (id: string): string =>
  paths.ACCESS_MANAGEMENT_DETAIL.replace(":id", id);
export const getClientsDetailsPath = (id: string): string =>
  paths.CLIENTS_DETAILS.replace(":id", id);
export const getClientsDetailsAlertPath = (id: string, alertId: string): string =>
  paths.CLIENTS_DETAILS_ALERT.replace(":id", id).replace(":alertId", alertId);
export const getClientsDetailsAlertsPath = (id: string): string =>
  paths.CLIENTS_DETAILS_ALERTS.replace(":id", id);
export const getClientsDetailsAlertsDetailsPath = (id: string, alertId: string): string =>
  paths.CLIENTS_DETAILS_ALERTS_DETAILS.replace(":id", id).replace(":alertId", alertId);
export const getClientsDetailsTransfersPath = (id: string): string =>
  paths.CLIENTS_DETAILS_TRANSFERS.replace(":id", id);
export const getClientsDetailsTransfersDetailsPath = (id: string, transferId: string): string =>
  paths.CLIENTS_DETAILS_TRANSFERS_DETAILS.replace(":id", id).replace(":transferId", transferId);
export const getClientsDetailsTransfersDetailsAlertsPath = (id: string, transferId: string): string =>
  paths.CLIENTS_DETAILS_TRANSFERS_DETAILS_ALERTS.replace(":id", id).replace(":transferId", transferId);
export const getClientsDetailsTransfersDetailsAlertPath = (id: string, transferId: string, alertId: string): string =>
  paths.CLIENTS_DETAILS_TRANSFERS_DETAILS_ALERT
    .replace(":id", id)
    .replace(":transferId", transferId)
    .replace(":alertId", alertId);

export const getClientsDetailsTransferPath = (id: string, transferId: string): string =>
  paths.CLIENTS_DETAILS_TRANSFER.replace(":id", id).replace(":transferId", transferId);

export const getClientsArchiveDetailsPath = (id: string): string =>
  paths.CLIENTS_ARCHIVE_DETAILS.replace(":id", id);
export const getClientsArchiveDetailsAlertPath = (id: string, alertId: string): string =>
  paths.CLIENTS_ARCHIVE_DETAILS_ALERT.replace(":id", id).replace(":alertId", alertId);
export const getClientsArchiveDetailsAlertsPath = (id: string): string =>
  paths.CLIENTS_ARCHIVE_DETAILS_ALERTS.replace(":id", id);
export const getClientsArchiveDetailsAlertsDetailsPath = (id: string, alertId: string): string =>
  paths.CLIENTS_ARCHIVE_DETAILS_ALERTS_DETAILS.replace(":id", id).replace(":alertId", alertId);
export const getClientsArchiveDetailsTransfersPath = (id: string): string =>
  paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS.replace(":id", id);
export const getClientsArchiveDetailsTransfersDetailsPath = (id: string, transferId: string): string =>
  paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS.replace(":id", id).replace(":transferId", transferId);

export const getClientsArchiveDetailsTransferPath = (id: string, transferId: string): string =>
  paths.CLIENTS_ARCHIVE_DETAILS_TRANSFER.replace(":id", id).replace(":transferId", transferId);
export const getClientsArchiveDetailsTransfersDetailsAlertsPath = (id: string, transferId: string): string =>
  paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS_ALERTS.replace(":id", id).replace(":transferId", transferId);
export const getClientsArchiveDetailsTransfersDetailsAlertPath =
  (id: string, transferId: string, alertId: string): string =>
    paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS_ALERT
      .replace(":id", id)
      .replace(":transferId", transferId)
      .replace(":alertId", alertId);
export const getFastChecksDetailsPath = (id: string): string =>
  paths.FAST_CHECKS_DETAILS.replace(":id", id);
export const getFastChecksDetailsRisksPath = (id: string, alertId: string): string =>
  paths.FAST_CHECKS_DETAILS_RISKS.replace(":id", id).replace(":alertId", alertId);
export const findIdFromUrl = (startFrom: string|undefined = "/"): string|undefined => {
  const matches = window.location.pathname.match(new RegExp(`${startFrom}(.*?(?=\\/|$))`));
  if (matches) { // Did it match?
    return matches[1];
  }
};
export const getClientId = ():string|undefined => findIdFromUrl("/clients/");
export const getMonitoringWalletId = (): string|undefined => findIdFromUrl("/transfers/monitored-wallets/"); 
export const getClientArchiveId = ():string|undefined => findIdFromUrl("/clients/archive/");
export const getTransferId = ():string|undefined => findIdFromUrl("/transfers/");

export const getWalletInformationPath = (id: string): string =>
  paths.TRANSFERS_MONITORED_WALLETS_INFORMATION.replace(":id", id);

export const getWalletArchiveDetailsTransfersPath = (id: string): string =>
  paths.TRANSFERS_MONITORED_WALLETS_INFORMATION_TRANSFERS.replace(":id", id);
