import { FC } from "react";
import { useDispatch } from "react-redux";

import { paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";
import FilterIcon from "components/Icons/FilterIcon";

import { openModal } from "store/modals/actions";
import { EModals } from "store/modals/types";

import MobileHeaderTransfersMenu from "./components/MobileHeaderTransfersMenu";
import MobileHeaderClientMenu from "./components/MobileHeaderClientMenu";
import MobileHeaderClientArchive from "./components/MobileHeaderClientArchive";
import MobileHeaderNewMember from "./components/MobileHeaderNewMember";
import MobileHeaderAccessManagementDelete from "./components/MobileHeaderAccessManagementDelete";
import MobileHeaderAddTransfer from "./components/MobileHeaderAddTransfer";
import MobileHeaderCheck from "./components/MobileHeaderCheck";
import MobileHeaderNewInvoice from "./components/MobileHeaderNewInvoice";
import MobileHeaderAddNewWallet from "./components/MobileHeaderAddNewWallet";
import MobileHeaderDeleteWallet from "./components/MobileHeaderDeleteWallet";
import MobileHeaderFastCheck from "./components/MobileHeaderFastCheck";

export const dropdownAlign: Record<string, [number, number]> = { offset: [0, 19] };
const MobileHeaderRight: FC = () => {
  const dispatch = useDispatch();
  const { routePath } = useLayoutAppNameContext();
  const filters: {[k in paths]?: {
    callback: () => void
  }} = {
    [paths.TRANSFERS_DETAILS_ALERTS]: {
      callback: () => {
        dispatch(openModal({ modal:EModals.alertsFilter }));
      }
    },
    [paths.CLIENTS_ARCHIVE]: {
      callback: () => {
        dispatch(openModal({ modal:EModals.clientsFilter }));
      }
    },
    [paths.CLIENTS_DETAILS_TRANSFERS]: {
      callback: () => {
        dispatch(openModal({ modal:EModals.transfersFilter }));
      }
    },
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS]: {
      callback: () => {
        dispatch(openModal({ modal:EModals.transfersFilter }));
      }
    },
    [paths.TRANSFERS_MONITORED_WALLETS_INFORMATION_TRANSFERS]: {
      callback: () => {
        dispatch(openModal({ modal:EModals.transfersFilter }));
      }
    },
  };

  const filter = routePath && filters[routePath]?.callback;

  return <div className="header-mobile__right">
    {routePath === paths.TRANSFERS && <MobileHeaderAddTransfer/>}
    {routePath === paths.TRANSFERS_DETAILS && <MobileHeaderTransfersMenu/>}
    {routePath === paths.FAST_CHECKS && <MobileHeaderCheck/>}
    {routePath === paths.FAST_CHECKS_DETAILS && <MobileHeaderFastCheck />}
    {routePath === paths.CLIENTS && <MobileHeaderClientArchive/>}
    {routePath === paths.CLIENTS_DETAILS && <MobileHeaderClientMenu/>}
    {routePath === paths.CLIENTS_ARCHIVE_DETAILS && <MobileHeaderClientMenu archive={true}/>}
    {routePath === paths.ACCESS_MANAGEMENT && <MobileHeaderNewMember/>}
    {routePath === paths.ACCESS_MANAGEMENT_DETAIL && <MobileHeaderAccessManagementDelete/>}
    {routePath === paths.INVOICES && <MobileHeaderNewInvoice/>}
    {routePath === paths.TRANSFERS_MONITORED_WALLETS && <MobileHeaderAddNewWallet/>}
    {routePath === paths.TRANSFERS_MONITORED_WALLETS_INFORMATION && <MobileHeaderDeleteWallet/>}
    {filter &&
      <div className="filter-logo" onClick={() => filters && filter()}><FilterIcon /></div>
    }
  </div>;
};

export default MobileHeaderRight;
