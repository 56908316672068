import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  getClientArchiveId,
  getClientId,
  getClientsArchiveDetailsAlertsPath,
  getClientsArchiveDetailsPath,
  getClientsArchiveDetailsTransfersDetailsAlertsPath,
  getClientsArchiveDetailsTransfersDetailsPath,
  getClientsArchiveDetailsTransfersPath,
  getClientsDetailsAlertsPath,
  getClientsDetailsPath,
  getClientsDetailsTransfersDetailsAlertsPath,
  getClientsDetailsTransfersDetailsPath,
  getMonitoringWalletId,
  getTransferId,
  getClientsDetailsTransfersPath,
  getTransfersDetailAlertsPath,
  getTransfersDetailPath, getWalletInformationPath,
  paths,
  monitoringParamsKey,
  clientParamsKey,
  getWalletArchiveDetailsTransfersPath,
} from "config/paths";

import FilterIcon from "components/Icons/FilterIcon";
import { useLayoutAppNameContext } from "components/Layout/hooks";

import { openModal } from "store/modals/actions";
import { EModals } from "store/modals/types";

import HeaderBreadCrumbs, { TBreadCrumb } from "../../../NewHeader/components/HeaderBreadCrumbs";

const MobileHeaderLeft: FC = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const doesAnyHistoryEntryExist = history.location.key;
  const { routePath } = useLayoutAppNameContext();
  const dispatch = useDispatch();
  const transferId = getTransferId();
  const walletId = getMonitoringWalletId();
  const clientId = getClientId();
  const clientArchiveId = getClientArchiveId();
  const queryParam = new URLSearchParams(window.location.search);
  const monitoringId = queryParam.get(monitoringParamsKey);
  const clientParamId = queryParam.get(clientParamsKey);

  const getTransferDetailPath = () => {
    if (monitoringId) {
      return handleClickBreadCrumb(getWalletArchiveDetailsTransfersPath(monitoringId as string));
    }

    if (clientParamId) {
      return handleClickBreadCrumb(getClientsDetailsTransfersPath(clientParamId));
    }

    return handleClickBreadCrumb(paths.TRANSFERS,true);
  };

  const filters: {[k in paths]?: {
    callback: () => void
  }} = {
    [paths.HOME]: {
      callback: () => {
        dispatch(openModal({ modal: EModals.alertsFilter }));
      }
    },
    [paths.TRANSFERS]: {
      callback: () => {
        dispatch(openModal({ modal: EModals.transfersFilter }));
      }
    },
    [paths.CLIENTS]: {
      callback: () => {
        dispatch(openModal({ modal:EModals.clientsFilter }));
      }
    },
    [paths.FAST_CHECKS]: {
      callback: () => {
        dispatch(openModal({ modal:EModals.fastChecksFilter }));
      }
    },
    [paths.INVOICES]: {
      callback: () => {
        dispatch(openModal({ modal:EModals.invoiceFilter }));
      }
    },
    [paths.TRANSFERS_MONITORED_WALLETS]: {
      callback: () => {
        dispatch(openModal({ modal:EModals.monitoredWalletFilter }));
      }
    },
  };

  const filter = routePath && filters[routePath]?.callback;

  const handleClickBreadCrumb = (path: string, withGoBack = false) => {
    if (doesAnyHistoryEntryExist && withGoBack) {
      history.goBack();
    } else {
      history.push(path);
      history.location.key = "";
    }
  };

  const breadCrumbs: Record<string, TBreadCrumb[]> = useMemo(() => ({
    [paths.ALERTS_DETAIL]: [{
      onClick: () => handleClickBreadCrumb(paths.HOME,true),
    }],
    [paths.ACCESS_MANAGEMENT_NEW]: [{
      onClick: () => handleClickBreadCrumb(paths.ACCESS_MANAGEMENT),
    }],
    [paths.ACCESS_MANAGEMENT_DETAIL]: [{
      onClick: () => handleClickBreadCrumb(paths.ACCESS_MANAGEMENT),
    }],
    [paths.TRANSFERS_DETAILS]: [{
      onClick: () => getTransferDetailPath(),
    }],
    [paths.TRANSFERS_DETAILS_ALERT]: [{
      onClick: () => {
        if (!transferId) {
          handleClickBreadCrumb(paths.TRANSFERS);
          return;
        }
        handleClickBreadCrumb(getTransfersDetailPath(transferId),true);
      },
    }],
    [paths.TRANSFERS_DETAILS_ALERTS]: [{
      onClick: () => {
        if (!transferId) {
          handleClickBreadCrumb(paths.TRANSFERS);
          return;
        }
        handleClickBreadCrumb(getTransfersDetailPath(transferId),true);
      },
    }],
    [paths.TRANSFERS_DETAILS_ALERTS_DETAILS]: [{
      onClick: () => {
        if (!transferId) {
          handleClickBreadCrumb(paths.TRANSFERS);
          return;
        }
        handleClickBreadCrumb(getTransfersDetailAlertsPath(transferId),true);
      },
    }],

    [paths.CLIENTS_DETAILS]: [{
      onClick: () => handleClickBreadCrumb(paths.CLIENTS,true),
    }],
    [paths.CLIENTS_DETAILS_TRANSFERS]: [{
      onClick: () => {
        if (!clientId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsDetailsPath(clientId),true);
      },
    }],
    [paths.CLIENTS_DETAILS_ALERT]: [{
      onClick: () => {
        if (!clientId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsDetailsPath(clientId),true);
      },
    }],
    [paths.CLIENTS_DETAILS_ALERTS]: [{
      onClick: () => {
        if (!clientId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsDetailsPath(clientId),true);
      },
    }],
    [paths.CLIENTS_DETAILS_ALERTS_DETAILS]: [{
      onClick: () => {
        if (!clientId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsDetailsAlertsPath(clientId),true);
      },
    }],
    [paths.CLIENTS_DETAILS_TRANSFERS_DETAILS]: [{
      onClick: () => {
        if (!clientId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsDetailsTransfersPath(clientId), true);
      },
    }],
    [paths.CLIENTS_DETAILS_TRANSFERS_DETAILS_ALERTS]: [{
      onClick: () => {
        if (!clientId || !transferId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsDetailsTransfersDetailsPath(clientId, transferId),true);
      },
    }],
    [paths.CLIENTS_DETAILS_TRANSFERS_DETAILS_ALERT]: [{
      onClick: () => {
        if (!clientId || !transferId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsDetailsTransfersDetailsAlertsPath(clientId, transferId), true);
      },
    }],
    [paths.CLIENTS_DETAILS_TRANSFER]: [{
      onClick: () => {
        if (!clientId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsDetailsPath(clientId),true);
      },
    }],

    [paths.CLIENTS_ARCHIVE]: [{
      onClick: () => handleClickBreadCrumb(paths.CLIENTS, true),
    }],
    [paths.CLIENTS_ARCHIVE_DETAILS_ALERT]: [{
      onClick: () => {
        if (!clientArchiveId) {
          handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
          return;
        }
        handleClickBreadCrumb(getClientsArchiveDetailsPath(clientArchiveId),true);
      },
    }],
    [paths.CLIENTS_ARCHIVE_DETAILS_ALERTS]: [{
      onClick: () => {
        if (!clientArchiveId) {
          handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
          return;
        }
        handleClickBreadCrumb(getClientsArchiveDetailsPath(clientArchiveId),true);
      },
    }],
    [paths.CLIENTS_ARCHIVE_DETAILS_ALERTS_DETAILS]: [{
      onClick: () => {
        if (!clientArchiveId) {
          handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
          return;
        }
        handleClickBreadCrumb(getClientsArchiveDetailsAlertsPath(clientArchiveId),true);
      },
    }],
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS]: [{
      onClick: () => {
        if (!clientArchiveId) {
          handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
          return;
        }
        handleClickBreadCrumb(getClientsArchiveDetailsPath(clientArchiveId),true);
      },
    }],
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS]: [{
      onClick: () => {
        if (!clientArchiveId) {
          handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
          return;
        }
        handleClickBreadCrumb(getClientsArchiveDetailsTransfersPath(clientArchiveId),true);
      },
    }],
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS_ALERTS]: [{
      onClick: () => {
        if (!clientArchiveId || !transferId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsArchiveDetailsTransfersDetailsPath(clientArchiveId, transferId),true);
      },
    }],
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFERS_DETAILS_ALERT]: [{
      onClick: () => {
        if (!clientArchiveId || !transferId) {
          handleClickBreadCrumb(paths.CLIENTS);
          return;
        }
        handleClickBreadCrumb(getClientsArchiveDetailsTransfersDetailsAlertsPath(clientArchiveId, transferId),true);
      },
    }],
    [paths.CLIENTS_ARCHIVE_DETAILS_TRANSFER]: [{
      onClick: () => {
        if (!clientArchiveId) {
          handleClickBreadCrumb(paths.CLIENTS_ARCHIVE);
          return;
        }
        handleClickBreadCrumb(getClientsArchiveDetailsPath(clientArchiveId),true);
      },
    }],
    [paths.FAST_CHECKS_DETAILS]: [{

      onClick: () => handleClickBreadCrumb(paths.FAST_CHECKS,true),
    }],
    [paths.TRANSFERS_MONITORED_WALLETS_INFORMATION]: [{
      onClick: () => handleClickBreadCrumb(paths.TRANSFERS_MONITORED_WALLETS,true),
    }],
    [paths.TRANSFERS_MONITORED_WALLETS_INFORMATION_TRANSFERS]: [{
      onClick: () => {
        if (!walletId) {
          handleClickBreadCrumb(paths.TRANSFERS_MONITORED_WALLETS,true);
          return;
        }
        handleClickBreadCrumb(getWalletInformationPath(walletId),true);
      },
    }],
  }), [i18n.language, clientId, transferId, clientArchiveId]);

  return (<div className="header-mobile__left">
    {filter &&
      <div className="filter-logo" onClick={() => filters && filter()}><FilterIcon /></div>
    }
    {routePath && breadCrumbs[routePath]
      && <HeaderBreadCrumbs breadCrumbs={breadCrumbs[routePath]} />}
  </div>);
};

export default MobileHeaderLeft;
