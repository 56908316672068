import { all, call, put, takeLatest, delay } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import request from "api";

import { TPaginationOptions } from "api/types";

import { CLIENTS_DETAILS_TRANSFERS_LIMIT }
  // eslint-disable-next-line max-len
  from "components/MonitoredWalletsDetails/components/WalletsInformation/components/MonitoredWalletsDetailsTransfersList";

import { transfersRequest } from "store/transfers/reducers";

import {
  INewWalletAction,
  INewWalletForm,
} from "../../components/MonitoredWallets/components/MonitoredNewWalletModal/schema";
import { getWalletInformationPath, paths } from "../../config/paths";
import { showSuccessGreen, showError } from "../../utils/notifications";
import i18n from "../../i18n";

import { IPayloadAction } from "../rootInterface";

import {
  addNewWalletFailure,
  addNewWalletRequest,
  addNewWalletSuccess,
  calculationFailure,
  calculationHistoricalFailure,
  calculationHistoricalRequest,
  calculationHistoricalSuccess,
  calculationRequest,
  calculationResult, checkHistoricalTransfersRequest, checkHistoricalTransfersUpdate, deleteMonitoredWalletFailure,
  deleteMonitoredWalletRequest,
  deleteMonitoredWalletSuccess,
  editWalletFailure,
  editWalletRequest,
  editWalletSuccess,
  monitoredWalletsFailure,
  monitoredWalletsRequest,
  monitoredWalletsSuccess,
  retryHistoryFailure,
  retryHistoryRequest,
  retryHistorySuccess,
  setHistoricalImportFailure,
  setHistoricalImportRequest,
  setHistoricalImportSuccess,
  setImportEnableRequest, startImportFailure,
  startImportRequest, startImportSuccess,
  stopImportingTransferFailure,
  stopImportingTransferRequest,
  stopImportingTransferSuccess,
} from "./reducers";
import {
  CalculationTransferResult,
  THistoricalImport,
  TMonitoredWallets,
  TMonitoredWalletsData,
  TMonitoredWalletsFilter,
  TMonitoredWalletsOptions,
  TNewWallet,
  TStartHistoricalImport,
} from "./types";

const monitoredWalletsParams = (filter?: TMonitoredWalletsFilter, pagination?: TPaginationOptions) => {
  const params = new URLSearchParams();

  if (filter?.asset) params.set("asset", String(filter?.asset));
  if (filter?.blockchain) params.set("blockchain", String(filter?.blockchain));
  if (filter?.date_from) params.set("date_from", String(filter?.date_from));
  if (filter?.date_to) params.set("date_to", String(filter?.date_to));
  if (filter?.search) params.set("search", String(filter?.search));

  if (pagination?.page) params.set("page", String(pagination?.page));
  if (pagination?.size) params.set("size", String(pagination?.size));

  return params;
};

function* monitoredWallets(action: IPayloadAction<Partial<TMonitoredWalletsOptions>>) {
  const params = monitoredWalletsParams(action.payload.filter, action.payload.pagination);
  try {
    const res: AxiosResponse<TMonitoredWalletsData> = yield call(request.get, "/monitored-wallets/", { params });
    yield put(monitoredWalletsSuccess({ data: res.data, infiniteScroll: action.payload.infiniteScroll }));
  } catch (error) {
    yield put(monitoredWalletsFailure(error));
  }
}

function* addNewWallet(action: IPayloadAction<INewWalletAction>) {
  try {
    const { payload } = action;
    const res: AxiosResponse<TNewWallet> = yield call(request.post, "/monitored-wallets/create/", payload?.value);
    yield put(addNewWalletSuccess(res.data));
    payload?.history.push(getWalletInformationPath(res.data.id));
  } catch (error) {
    yield put(addNewWalletFailure(error));
  }
}

function* historicalImport(action: IPayloadAction<{id: string,routePath:any}>) {
  const { id,routePath } = action.payload;
  try {
    const res: AxiosResponse<THistoricalImport> = yield call(request.get, `/monitored-wallets/${id}/`);
    yield put(setHistoricalImportSuccess(res.data));
    if(routePath === paths.TRANSFERS_MONITORED_WALLETS_INFORMATION) {
      yield put(transfersRequest({
        pagination: {
          limit: CLIENTS_DETAILS_TRANSFERS_LIMIT
        },
        filter: { monitored_wallet: id }
      }));
    }
  } catch (error) {
    yield put(setHistoricalImportFailure(error));
  }
}

function* setImportEnable(action: IPayloadAction<TMonitoredWallets>) {
  try {
    const { id, live_import_enabled } = action.payload;
    yield call(request.patch, `/monitored-wallets/${id}/set-enabled/`, { enabled: !live_import_enabled });
  } catch (error) {
    yield put(monitoredWalletsRequest({}));
  }
}

function* calculationTransfer(action: IPayloadAction<INewWalletForm>) {
  try {
    const {
      address,
      assets,
      network,
      direction,
      min_value_usd,
      historical_import_start_date
    } = action.payload;

    const data = {
      address,
      tokens: assets,
      network,
      direction,
      min_value_usd,
      min_datetime: new Date(),
      start_date: historical_import_start_date || null
    };

    yield delay(500);

    const res: AxiosResponse<CalculationTransferResult> =
      yield call(request.post, `/monitored-wallets/calculate-transfers-to-import/`, data);

    yield put(calculationResult(res.data));
  } catch (error) {
    showError(i18n.t("errors.somethingWentWrong"));
    yield put(calculationFailure(error));
  }
}

function* calculationHistoricalTransfer(action: IPayloadAction<TStartHistoricalImport>) {
  try {
    yield delay(500);

    const res: AxiosResponse<CalculationTransferResult> =
    yield call(request.post, `/monitored-wallets/${action.payload.id}/calculate-historical-transfers/`, action.payload);
    yield put(calculationHistoricalSuccess(res.data));
  } catch (error) {
    showError(i18n.t("errors.somethingWentWrong"));
    yield put(calculationHistoricalFailure(error));
  }
}

function* startImportEffect(action: IPayloadAction<TStartHistoricalImport>) {
  try {
    const { id } = action.payload;
    const res: AxiosResponse = yield call(request.post, `/monitored-wallets/${id}/start-historical-import/`,
      action.payload);
    yield put(startImportSuccess(res.data));
    yield put(setHistoricalImportSuccess(res.data));
  } catch (error) {
    yield put(startImportFailure(error));
  }
}

function* deleteMonitoredWalletSaga(action: IPayloadAction<{id: string, delete_transfers: boolean}>) {
  try {
    const { id, delete_transfers } = action.payload;
    yield call(request.post, `/monitored-wallets/${id}/delete/`, { delete_transfers });
    yield put(deleteMonitoredWalletSuccess());
    showSuccessGreen({ message: i18n.t("notification.successfully") });
  } catch (error) {
    if (error.response.status === 400) {
      showError(i18n.t("errors.somethingWentWrong"));
    }
    yield put(deleteMonitoredWalletFailure(error));
  }
}

function* stopImportingSaga(action: IPayloadAction<{id: string}>) {
  try {
    const { id } = action.payload;
    const res: AxiosResponse<THistoricalImport> =
    yield call(request.post, `/monitored-wallets/${id}/cancel-historical-import/`,
      action.payload);

    yield put(setHistoricalImportSuccess(res.data));
    yield put(stopImportingTransferSuccess());
  } catch (error) {
    if (error?.response?.status === 400) {
      showError(i18n.t("errors.somethingWentWrong"));
    }
    yield put(stopImportingTransferFailure(error));
  }
}

function* retryHistorySaga(action: IPayloadAction<{id: string}>) {
  try {
    const { id } = action.payload;
    const res: AxiosResponse<THistoricalImport> =
    yield call(request.post, `/monitored-wallets/${id}/retry-historical-import/`);

    yield put(retryHistorySuccess(res.data));
    yield put(setHistoricalImportSuccess(res.data));
  } catch (error) {
    yield put(retryHistoryFailure(error));
  }
}

function* checkHistoricalTransfersSaga(action: IPayloadAction<{id: string}>) {
  try {
    const { id } = action.payload;
    const res: AxiosResponse<THistoricalImport> =
    yield call(request.post, `/monitored-wallets/${id}/check-historical-transfers/`);

    yield put(setHistoricalImportSuccess(res.data));
    yield put(checkHistoricalTransfersUpdate('loaded'));
  } catch (error) {
    showError(i18n.t("errors.somethingWentWrong"));
    yield put(checkHistoricalTransfersUpdate(null));
  }
}

function* editWallet(action: IPayloadAction<Partial<THistoricalImport<string[]>>>) {
  try {
    const {
      name,
      client_ext_id,
      address,
      assets,
      direction,
      min_value_usd,
      live_import_enabled,
      live_checking_enabled,
      id,
    } = action.payload;
    yield call(request.patch, `/monitored-wallets/${id}/edit/`,
      {
        name,
        client_ext_id: client_ext_id || null,
        address,
        assets,
        live_import_direction: direction,
        live_import_min_value_usd: min_value_usd,
        live_import_enabled,
        live_checking_enabled,
      });
    yield put(editWalletSuccess());
    yield put(setHistoricalImportRequest({ id: (id as string) }));
    showSuccessGreen({
      message: i18n.t("notification.successfully"),
    });
  } catch (error) {
    yield put(editWalletFailure(error));
  }
}

function* Saga(): Generator {
  yield all([takeLatest(monitoredWalletsRequest.type, monitoredWallets)]);
  yield all([takeLatest(addNewWalletRequest.type, addNewWallet)]);
  yield all([takeLatest(setImportEnableRequest.type, setImportEnable)]);
  yield all([takeLatest(setHistoricalImportRequest.type, historicalImport)]);
  yield all([takeLatest(calculationRequest.type, calculationTransfer)]);
  yield all([takeLatest(calculationHistoricalRequest.type, calculationHistoricalTransfer)]);
  yield all([takeLatest(startImportRequest.type, startImportEffect)]);
  yield all([takeLatest(editWalletRequest.type, editWallet)]);
  yield all([takeLatest(retryHistoryRequest.type, retryHistorySaga)]);
  yield all([takeLatest(deleteMonitoredWalletRequest.type, deleteMonitoredWalletSaga)]);
  yield all([takeLatest(stopImportingTransferRequest.type, stopImportingSaga)]);
  yield all([takeLatest(checkHistoricalTransfersRequest.type, checkHistoricalTransfersSaga)]);
  
}

export default Saga;
