import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import request from "api";

import { TPaginationOptions } from "api/types";

import hexToRgb from "utils/hexToRgb";
import { getRiskGradientColor } from "utils/colorGradient/RiskGradient";
import { showErrorRed, showSuccessGreen } from "utils/notifications";
import delay from "utils/delay";

import i18n from "../../i18n";

import { IApplicationState, IPayloadAction } from "../rootInterface";
import { PAGINATION_PAGE_LIMIT } from "../constants";

import {
  transferBindTransactionFailure,
  transferBindTransactionRequest,
  transferBindTransactionSuccess,
  transferFindTransactionToBindFailure,
  transferFindTransactionToBindRequest,
  transferFindTransactionToBindSuccess,
  transferRecheckCounterpartyFailure,
  transferRecheckCounterpartyRequest,
  transferRecheckCounterpartySuccess,
  transferRecheckExposureFailure,
  transferRecheckExposureRequest,
  transferRecheckExposureSuccess,
  transferRegisterAttemptFailure,
  transferRegisterAttemptRequest,
  transferRegisterAttemptSuccess,
  transferRegisterFailure,
  transferRegisterRequest,
  transferRegisterSuccess,
  transferRisksFailure,
  transferRisksRequest,
  transferRisksSuccess,
  transfersDetailsEdit,
  transfersDetailsFailure,
  transfersDetailsRequest,
  transfersDetailsSuccess,
  transfersFailure,
  transfersRequest,
  transfersSuccess
} from "./reducers";
import {
  ECheckingResult,
  ECounterpartyStatus,
  EExposureStatus,
  ERegisteringResult,
  EUnknownRiskReason,
  TTransferBindTransactionOptions,
  TTransferData,
  TTransferDetails,
  TTransferDetailsOptions,
  TTransferDetailsState,
  TTransferFindTransactionToBindData,
  TTransferFindTransactionToBindOptions,
  TTransferOptions,
  TTransferRecheckCounterparty,
  TTransferRecheckCounterpartyOptions,
  TTransferRecheckExposure,
  TTransferRecheckExposureOptions,
  TTransferRegisterAttemptOptions,
  TTransferRegisterOptions,
  TTransferRegisterResponseData,
  TTransferRisksData,
  TTransferRisksOptions,
  TTransfersFilter
} from "./types";

const transfersParams = (
  filter: TTransfersFilter|undefined,
  pagination: TPaginationOptions|undefined
) => {
  const params = new URLSearchParams();

  if (filter?.type) params.set("type", String(filter?.type));
  if (filter?.risk_level) params.set("risk_level", String(filter?.risk_level));
  if (filter?.counterparty) params.set("counterparty", String(filter?.counterparty));
  if (filter?.asset) params.set("asset", String(filter?.asset));
  if (filter?.blockchain) params.set("network", String(filter?.blockchain));
  if (filter?.date_from) params.set("date_from", String(filter?.date_from));
  if (filter?.date_to) params.set("date_to", String(filter?.date_to));
  if (filter?.search) params.set("search", String(filter?.search));
  if (filter?.client) params.set("client", String(filter?.client));
  if (filter?.monitored_wallet) params.set("monitored_wallet", String(filter?.monitored_wallet));

  if (pagination?.limit) params.set("limit", String(pagination?.limit));
  params.set("offset", pagination?.offset ? String(pagination?.offset) : "0");
  // params.set("archived", "false");
  return params;
};

function* transfers(action: IPayloadAction<TTransferOptions>): unknown {
  const params = transfersParams(action.payload.filter, action.payload.pagination);

  try {
    const response: AxiosResponse<TTransferData> = yield call(request.get, "/transfers/", { params });
    const haveChecking = response.data.results
      .some(transfer => transfer.unknown_risk_reason === EUnknownRiskReason.checking);

    yield put(transfersSuccess({
      data:  response.data,
      infiniteScroll: action.payload.infiniteScroll,
      pagination: action.payload.pagination,
    }));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();

    if (haveChecking) {
      yield delay(5000);
      yield call(transfers, action);
    }

  } catch (error) {
    yield put(transfersFailure(error));
  }
}

function* transfersDetails(action: IPayloadAction<TTransferDetailsOptions>) {
  const { data: transferDetails
  }: TTransferDetailsState = yield select((state: IApplicationState) => state.transfers.transfersDetails);

  const { id } = action.payload;
  try {
    const response: AxiosResponse<TTransferDetails> = yield call(request.get, `/transfers/${id}/`);
    const indirectExposure = [...response.data?.exposure?.indirect_exposure || []].sort((a, b) => b.share - a.share );
    const counterpartyExposure = [...response.data?.counterparty?.exposure || []].sort((a, b) => b.share - a.share );

    yield put(transfersDetailsSuccess({
      ...response.data,
      exposure: response.data.exposure ? {
        ...response.data.exposure,
        indirect_exposure: indirectExposure.map(item => {
          const color = getRiskGradientColor(item.risk_score);
          return ({
            ...item,
            color,
            rgb: color ? hexToRgb(color) : undefined
          });
        })
      } : null,
      counterparty: response.data.counterparty ? {
        ...response.data.counterparty,
        exposure: counterpartyExposure.map(item => {
          const color = getRiskGradientColor(item.risk_score);
          return ({
            ...item,
            color,
            rgb: color ? hexToRgb(color) : undefined
          });
        })
      } : null,
    }));

    if (response.data.exposure_status === EExposureStatus.checking
      || response.data.counterparty_status === ECounterpartyStatus.checking) {
      yield delay(5000);
      yield put(transfersDetailsRequest({
        ...action.payload,
        prev_exposure_status: response.data.exposure_status,
        prev_counterparty_status: response.data.counterparty_status,
      }));
    }
    if ((
      action.payload.prev_exposure_status === EExposureStatus.checking
        && response.data.exposure_status === EExposureStatus.checked
    )
      || (
        action.payload.prev_counterparty_status === ECounterpartyStatus.checking
        && response.data.counterparty_status === ECounterpartyStatus.checked
      )
    ) {
      yield put(transferRisksRequest({
        query: {
          transfer: id,
          size: PAGINATION_PAGE_LIMIT
        },
      }));
    }
  } catch (error) {
    if (transferDetails?.exposure_status === EExposureStatus.checking) {
      yield put(transfersDetailsEdit({
        exposure_status: EExposureStatus.error,
      }));
    }
    if (transferDetails?.counterparty_status === ECounterpartyStatus.checking) {
      yield put(transfersDetailsEdit({
        counterparty_status: ECounterpartyStatus.error,
      }));
    }

    yield put(transfersDetailsFailure(error));
  }
}

function* transferRegister(action: IPayloadAction<TTransferRegisterOptions>) {
  const { data } = action.payload;
  try {
    const response: AxiosResponse<TTransferRegisterResponseData> = yield call(
      request.post, "/transfers/register/", data);
    yield put(transferRegisterSuccess(response.data));
    if (action.payload?.callOnSuccess) action.payload.callOnSuccess(response.data.id);

    if (
      response.data.registering_result === ERegisteringResult.started
      || response.data.registering_result === ERegisteringResult.completed
    ) {
      showSuccessGreen({
        message: i18n.t("notification.transferRegistered"),
        description: i18n.t("notification.transferRegisteredDescription")
      });
    }
    if (response.data.registering_result === ERegisteringResult.already_registered) {
      showSuccessGreen({
        message: i18n.t("notification.transferAlreadyRegistered"),
        description: i18n.t("notification.transferAlreadyRegisteredDescription")
      });
    }
    if (response.data.checking_result === ECheckingResult.no_checks) {
      showErrorRed({
        message: i18n.t("notification.checkingTheTransferCancelled"),
        description: i18n.t("notification.checkingTheTransferCancelledDescription")
      });
    }
    if (response.data.checking_result === ECheckingResult.not_supported) {
      showErrorRed({
        message: i18n.t("notification.checkingTheTransferCancelled"),
        description: i18n.t("notification.checkingTheTransferCancelledNotSupportedDescription")
      });
    }
  } catch (error) {
    yield put(transferRegisterFailure(error));
  }
}

function* transferRegisterAttempt(action: IPayloadAction<TTransferRegisterAttemptOptions>) {
  const { data } = action.payload;
  try {
    const response: AxiosResponse<TTransferRegisterResponseData> = yield call(
      request.post, "/transfers/register-attempt/", data);
    yield put(transferRegisterAttemptSuccess(response.data));
    if (action.payload?.callOnSuccess) action.payload.callOnSuccess(response.data.id);

    if (
      response.data.registering_result === ERegisteringResult.started
      || response.data.registering_result === ERegisteringResult.completed
    ) {
      showSuccessGreen({
        message: i18n.t("notification.transferRegistered"),
        description: i18n.t("notification.transferRegisteredDescription")
      });
    }
    if (response.data.registering_result === ERegisteringResult.already_registered) {
      showSuccessGreen({
        message: i18n.t("notification.transferAlreadyRegistered"),
        description: i18n.t("notification.transferAlreadyRegisteredDescription")
      });
    }
    if (response.data.checking_result === ECheckingResult.no_checks) {
      showErrorRed({
        message: i18n.t("notification.checkingTheTransferCancelled"),
        description: i18n.t("notification.checkingTheTransferCancelledDescription")
      });
    }
    if (response.data.checking_result === ECheckingResult.not_supported) {
      showErrorRed({
        message: i18n.t("notification.checkingTheTransferCancelled"),
        description: i18n.t("notification.checkingTheTransferCancelledNotSupportedDescription")
      });
    }
  } catch (error) {
    yield put(transferRegisterAttemptFailure(error));
  }
}


function* transferBindTransaction(action: IPayloadAction<TTransferBindTransactionOptions>) {
  const { data , id } = action.payload;
  try {
    const response: AxiosResponse<TTransferRegisterResponseData> = yield call(
      request.post, `/transfers/${id}/bind-transaction/`, data);
    yield put(transferBindTransactionSuccess(response.data));
    yield put(transfersDetailsRequest({ id }));
    if (action.payload?.callOnSuccess) action.payload.callOnSuccess(response.data.id);

    showSuccessGreen({
      message: i18n.t("notification.transactionBound"),
      description: i18n.t("notification.transactionBoundDescription")
    });
  } catch (error) {
    yield put(transferBindTransactionFailure(error));
  }
}

function* transferRecheckExposure(action: IPayloadAction<TTransferRecheckExposureOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse<TTransferRecheckExposure> = yield call(
      request.post, `/transfers/${id}/recheck-exposure/`);
    yield put(transferRecheckExposureSuccess(response.data));
    yield put(transfersDetailsEdit({
      exposure_status: response.data.exposure_status
    }));

    if (response.data.checking_result === ECheckingResult.started) {
      yield put(transfersDetailsRequest({ id }));
    }
    if (response.data.checking_result === ECheckingResult.no_checks) {
      showErrorRed({
        message: i18n.t("notification.checkingTheTransferCancelled"),
        description: i18n.t("notification.checkingTheTransferCancelledDescription")
      });
    }
    if (response.data.checking_result === ECheckingResult.not_supported) {
      showErrorRed({
        message: i18n.t("notification.checkingTheTransferCancelled"),
        description: i18n.t("notification.checkingTheTransferCancelledNotSupportedDescription")
      });
    }
  } catch (error) {
    yield put(transferRecheckExposureFailure(error));
  }
}
function* transferRecheckCounterparty(action: IPayloadAction<TTransferRecheckCounterpartyOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse<TTransferRecheckCounterparty> = yield call(
      request.post, `/transfers/${id}/recheck-counterparty/`);
    yield put(transferRecheckCounterpartySuccess(response.data));
    yield put(transfersDetailsEdit({
      counterparty_status: response.data.counterparty_status
    }));

    if (response.data.checking_result === ECheckingResult.started) {
      yield put(transfersDetailsRequest({ id }));
    }
    if (response.data.checking_result === ECheckingResult.no_checks) {
      showErrorRed({
        message: i18n.t("notification.checkingTheTransferCancelled"),
        description: i18n.t("notification.checkingTheTransferCancelledDescription")
      });
    }
    if (response.data.checking_result === ECheckingResult.not_supported) {
      showErrorRed({
        message: i18n.t("notification.checkingTheTransferCancelled"),
        description: i18n.t("notification.checkingTheTransferCancelledNotSupportedDescription")
      });
    }
  } catch (error) {
    yield put(transferRecheckCounterpartyFailure(error));
  }
}

function* transferRisks(action: IPayloadAction<TTransferRisksOptions>) {
  try {
    const response: AxiosResponse<TTransferRisksData> =
      yield call(request.get, "/transfers/risks/", { params: action.payload.query });
    yield put(transferRisksSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(transferRisksFailure(error));
  }
}

function* transferFindTransactionToBind(action: IPayloadAction<TTransferFindTransactionToBindOptions>) {
  try {
    const response: AxiosResponse<TTransferFindTransactionToBindData> =
      yield call(request.post, `/transfers/${action.payload.id}/find-transaction-to-bind/`, action.payload.body);
    yield put(transferFindTransactionToBindSuccess(response.data));
  } catch (error) {
    yield put(transferFindTransactionToBindFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(transfersRequest.type, transfers),
    takeLatest(transfersDetailsRequest.type, transfersDetails),
    takeLatest(transferRegisterRequest.type, transferRegister),
    takeLatest(transferRegisterAttemptRequest.type, transferRegisterAttempt),
    takeLatest(transferBindTransactionRequest.type, transferBindTransaction),
    takeLatest(transferRecheckExposureRequest.type, transferRecheckExposure),
    takeLatest(transferRecheckCounterpartyRequest.type, transferRecheckCounterparty),
    takeLatest(transferRisksRequest.type, transferRisks),
    takeLatest(transferFindTransactionToBindRequest.type, transferFindTransactionToBind),
  ]);
}

export default Saga;
